<template>
    <lenis ref="lenisRef">
        <div
            class="relative overflow-x-hidden selection:text-gray-400 selection:bg-light"
        >
            <div
                ref="nuxtStartupTransitionContainer"
                class="w-screen min-h-screen transition-opacity duration-75 bg-white"
                data-scroll-container
            >
                <!-- <Navigation /> -->
                <!-- <SocialMediaFixed /> -->
                <NuxtPage data-scroll-section />
                <RightsBar data-scroll-section class="-mt-0.5" />
                <!-- <Footer data-scroll-section /> -->
            </div>
        </div>
    </lenis>
</template>
